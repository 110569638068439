
let scrollViewSlide = () => {
  let options = {
    root: document.querySelector("#scrollArea"),
    rootMargin: "0px",
    threshold: 0.9,
  };
  
  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        let elem = entry.target;
        elem.classList.add('in-view');
      }
      else {
        let elem = entry.target;
        elem.classList.remove('in-view');
      }
    });
  };

  let observer = new IntersectionObserver(intersectionCallback, options);

  let target = document.querySelector(".vinyl-record");
  
  if (target) {
    observer.observe(target);
  }
    

  
}

export default scrollViewSlide