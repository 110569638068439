class Submissions {
  constructor() {
    this.heading = document.querySelector(".section-heading");
    this.writingButton = document.querySelector("#writing-button");
    this.photographyButton = document.querySelector("#photography-button");
    this.writingGuidelines = document.querySelector("#writing-guidelines");
    this.photographyGuidelines = document.querySelector("#photography-guidelines");
    this.copyButton = document.querySelector(".copy-btn");
    this.copyText = document.getElementById("copy-text");
    
    this.events();
  }

  events() {
    if(this.writingButton && this.photographyButton && this.copyButton) {
    this.writingButton.addEventListener("click", e => {
      e.preventDefault();
      this.showWriting();
    });

    this.photographyButton.addEventListener("click", e => {
      e.preventDefault();
      this.showPhotography();
    });

    this.copyButton.addEventListener("click", e => {
      e.preventDefault();
      this.copyEmail();
    });
  }
  }

  showWriting() {
    this.photographyGuidelines.classList.add('hidden');
    this.photographyButton.classList.remove('selected');
    this.writingGuidelines.classList.remove('hidden');
    this.writingButton.classList.add('selected');
    this.heading.innerHTML = 'SUBMISSION GUIDELINES: WRITING'
  }

  showPhotography() {
    this.writingGuidelines.classList.add('hidden');
    this.writingButton.classList.remove('selected');
    this.photographyGuidelines.classList.remove('hidden');
    this.photographyButton.classList.add('selected');
    this.heading.innerHTML = 'SUBMISSION GUIDELINES: PHOTOGRAPHY'
  }

  copyEmail() {
    navigator.clipboard.writeText(this.copyText.innerText);
    alert("Email successfully copied!");
  }
}

export default Submissions