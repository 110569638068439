class GalleryZoom {

  constructor() {
    this.galleryOverlay = document.querySelector('.gallery-overlay');

    if (this.galleryOverlay) {
      this.galleryImages = document.querySelectorAll('.wp-caption img, .gallery-main-image img');
      this.overlayImage = document.querySelector('.gallery-overlay-image');
      this.events();
    }
  }

  events = () => {
    this.galleryImages.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        this.openImageOverlay(e);
      })
    })

    this.galleryOverlay.addEventListener("click", e => {
      e.preventDefault();
      this.closeImageOverlay();
    })
  }

  openImageOverlay = (e) => {
    this.galleryOverlay.classList.toggle("hidden")
    document.body.classList.toggle("body-no-scroll");
    this.overlayImage.src = e.target.src;
  }

  closeImageOverlay = () => {
    this.galleryOverlay.classList.toggle("hidden")
    document.body.classList.toggle("body-no-scroll");
    this.overlayImage.src = "";
  }

}

export default GalleryZoom;