import Glide from "@glidejs/glide"

class HeroSlider {
  constructor() {
    if (document.querySelector(".hero-slider")) {

      // count how many slides there are
      const slideCount = document.querySelectorAll(".slide").length;
      const counterEl = document.querySelector(".hero-counter");

      // Actually initialize the glide / slider script
      var glide = new Glide(".hero-slider", {
        type: "carousel",
        perView: 1,
        gap: 0,
        animationDuration: 700,
        autoplay: 5000

      })

      glide.on('move', function() {
        counterEl.innerHTML = `${glide.index + 1}/${slideCount}`;
      })

      glide.mount()
    }
  }
}

export default HeroSlider
