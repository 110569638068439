import axios from "axios"
import poloroidFrame from "../../images/poloroid_4.png"
import guestProfile from "../../images/guest-profile.png"

class Search {
  // 1. describe and create/initiate our object
  constructor() {
    // this.addSearchHTML();
    this.resultsDiv = document.querySelector(".search-results");
    this.resultsSummary = document.querySelector(".results-summary");
    this.openButton = document.querySelectorAll(".js-search-trigger");
    this.closeButton = document.querySelector(".close-search-btn");
    this.searchOverlay = document.querySelector(".search-overlay");
    this.searchField = document.querySelector("#search-input");
    this.isOverlayOpen = false;
    this.isSpinnerVisible = false;
    this.previousValue;
    this.typingTimer;
    this.events();
  }

  // 2. events
  events() {
    this.openButton.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        this.openOverlay();
      })
    })

    this.closeButton.addEventListener("click", e => {
      e.preventDefault();
      this.closeOverlay()
    });
    document.addEventListener("keydown", e => this.keyPressDispatcher(e));
    this.searchField.addEventListener("keyup", () => this.typingLogic());
  }

  // 3. methods (function, action...)
  typingLogic() {
    if (this.searchField.value != this.previousValue) {
      clearTimeout(this.typingTimer);
      if (this.searchField.value) {
        if (!this.isSpinnerVisible) {
          this.resultsSummary.innerHTML = "";
          this.resultsDiv.innerHTML = '<div class="spinner-loader"></div>';
          this.isSpinnerVisible = true;
        }
        this.typingTimer = setTimeout(this.getResults.bind(this), 750);
      } else {
        this.resultsDiv.innerHTML = "";
        this.resultsSummary.innerHTML = "";
        this.isSpinnerVisible = false;
      }
    }

    this.previousValue = this.searchField.value;
  }

  async getResults() {
    try {
      const response = await axios.get(websiteData.root_url + "/wp-json/website/v1/search?term=" + this.searchField.value);
      const results = response.data;
      let resultsCount = 0;
      
      for(const resultType in results) {
        resultsCount += results[resultType].length;
      }

      this.resultsSummary.innerHTML = `There are ${resultsCount} result(s) for “${this.searchField.value}”`

      this.resultsDiv.innerHTML = `
        <div class="page-section articles-section">
          <h2 class="section-heading">ARTICLES (${results.articles.length})</h2>
          <div class="article-card-container">
          ${results.articles.length ? 
            results.articles.map(article => {

              return `
              <div class="article-card">
                <a href="${article.permalink}">
                  <div class="article-thumbnail">
                    <img src="${article.thumbnail.url}" alt="${article.thumbnail.alt}" />
                  </div>
                  <div class="article-card-info">
                    <h3>${article.title}</h3>
                    <div class="article-summary-container">
                      <p class="article-summary">${article.excerpt}</p>
                    </div>
                    <div class="article-accent orange"></div>
                    <p class="article-meta-info">
                      ${article.author.post_title}
                      <br>
                      ${article.date}
                    </p>
                    <div class="article-accent red"></div>
                  </div>
                </a>
              </div>
              `
            }) 
          
          
          : 
            `<p>No articles match that search.</p>`
          }
          </div>
          <div class="button-container">
            <a class="page-button" href='${websiteData.root_url}/articles}'>View All Articles</a>
          </div>
        </div>

        <div class="page-section podcasts-section">
          <h2 class="section-heading">PODCAST EPISODES (${results.podcasts.length})</h2>
          <div class="podcasts-container">
            ${results.podcasts.length ? 
              `<ul class="podcast-list">
                ${results.podcasts.map(podcast => {
                  return `
                    <li>
                      <div class="podcast-item">
                        <h3 class="podcast-title">${podcast.title}</h3>
                        <p class="podcast-details">${podcast.length} Minutes - ${podcast.date}</p>
                        <div class="podcast-button-container">
                          <a class="thin-button spotify-button red" href='${podcast.link.url}' target="_blank">${podcast.link.title}</a>
                          <a class="thin-button read-transcript-button" href='${podcast.permalink}'>More Info</a>
                        </div>
                      </div>
                    </li>
                  `
                })}
              </ul>`
            : 
              `<p>No podcast episodes match that search.</p>`
            }
          </div>
          <div class="button-container">
            <a class="page-button" href='${websiteData.root_url}/podcasts}'>View All Podcasts</a>
          </div>
        </div>

        <div class="page-section photos-section">
          <h2 class="section-heading">GALLERIES (${results.galleries.length})</h2>
          ${results.galleries.length ?
            `<div class="photo-cards-container">
              ${results.galleries.map(gallery => {
                return `
                <a href="${gallery.permalink}">
                  <div class="photo-card">
                    <img src="${gallery.thumbnail.url}" alt="${gallery.thumbnail.alt}"/>

                    <div class="photo-card-info">
                        <h3>${gallery.title}</h3>
                        <p>${gallery.photographer.post_title}<br>${gallery.date}<br><br>25 Photos</p>
                    </div>
                  </div>
                </a>
                `
              })}
            </div>`
          : 
              `<p>No galleries match that search.</p>`
          }
          <div class="button-container">
            <a class="page-button" href='${websiteData.root_url}/galleries}'>View All Galleries</a>
          </div>
        </div>

        <div class="page-section staff-section">
          <h2 class="section-heading">STAFF (${results.contributors.length})</h2>
          ${results.contributors.length ?
            `<div class="staff-container"> 
              ${results.contributors.map(contributor => {
                return `
                  <a href="${contributor.permalink}">
                  <div class="staff-card" style="background: url(${poloroidFrame}) no-repeat; background-size: contain">
                  ${contributor.thumbnail ?
                    `<img class="staff-image" src="${contributor.thumbnail.url}" alt="${contributor.thumbnail.alt}"/>`
                  :
                  `<img class="staff-image" src="${guestProfile}" alt="guest profile image"/>`
                }
                    <div class="staff-info">
                      <h3>${contributor.name}</h3>
                      <p>${contributor.category[0].name}</p>
                    </div>

                  </div>
                  </a>`
              })}
            </div>`
          : 
            `<p>No staff members match that search.</p>`
          }
          <div class="button-container">
            <a class="page-button" href='${websiteData.root_url}/staff}'>View All Staff</a>
          </div>
        </div>
      `
      this.isSpinnerVisible = false;
    } catch (e) {
      console.log(e);
    }
  }

  keyPressDispatcher(e) {
    if (e.keyCode == 27 && this.isOverlayOpen) {
      this.closeOverlay();
    }
  }

  openOverlay() {
    this.searchOverlay.classList.remove("hidden");
    document.body.classList.add("body-no-scroll");
    this.searchField.value = "";
    setTimeout(() => this.searchField.focus(), 301);
    this.isOverlayOpen = true;
    return false;
  }

  closeOverlay() {
    this.searchOverlay.classList.add("hidden");
    document.body.classList.remove("body-no-scroll");
    this.isOverlayOpen = false;
  }
}

export default Search;
