class MobileMenu {
  constructor() {
    this.menu = document.querySelector(".mobile-backdrop");
    this.openButton = document.querySelector(".collapsed-menu-trigger");
    this.closeButton = document.querySelector(".exit-menu-btn");
    this.events();
  }

  events() {
    this.openButton.addEventListener("click", () => this.openMenu());
    this.closeButton.addEventListener("click", () => this.openMenu());
  }

  openMenu() {
    this.menu.classList.toggle("hidden")
    document.body.classList.toggle("body-no-scroll");
  }
}

export default MobileMenu
