
class EventNav {

  constructor() {
    this.eventButtons = document.querySelectorAll('.event-nav');
    this.events();
  }

  events = () => {
    this.eventButtons.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        this.scrollToEvents();
      })
    })
  }

  scrollToEvents = () => {
    // simulates arriving at #Who-We-Are for the purpose of this example
    // window.location.hash = '#events';
    window.location = 'http://the-record-underground.local';
    const element = document.getElementById("events");

    addEventListener('DOMContentLoaded', event => {
      if (window.location.hash === 'events') {
        element.scrollIntoView({behavior: "smooth", block: "start"});
      }
    });
    
  }

}

export default EventNav;