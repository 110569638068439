import "../css/style.scss"
import scrollViewSlide from "./modules/PodcastVinyl";
import EventNav from "./modules/Events"
import MobileMenu from "./modules/MobileMenu"
import HeroSlider from "./modules/HeroSlider";
import Search from "./modules/Search";
import Submissions from "./modules/Submissions";
import GalleryZoom from "./modules/GalleryZoom";

const mobileMenu = new MobileMenu()
const heroSlider = new HeroSlider();
scrollViewSlide();
const search = new Search();
const eventNav = new EventNav();
const submissions = new Submissions();
const galleryZoom = new GalleryZoom();

